

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_CONTRACTS_URL } from '../../connection.data';

@Injectable()
export class ContractService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_CONTRACTS_URL;
    }

    toRevision(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/status/revision/${item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    toDraft(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/status/draft/${item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    preApprove(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/status/preapprove/${item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    approve(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/status/approve/${item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }

    reject(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/status/reject/${item.get('id')}`, item).pipe(
            map((response)=>response.status)
        );
    }
}